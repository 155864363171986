import * as authClient from '../../utils/auth-client'
import { authActionTypes } from './AuthContext'

export function fetchAuth(dispatch) {
  return authClient.custom('/', null).then((response) => {
    const [data] = response
    if (data) {
      dispatch({ type: authActionTypes.INIT_AUTH, payload: data.auth })
    }
    return response
  })
}

export function signIn(dispatch, email, password) {
  return authClient.custom('/sign-in', { email, password }).then((response) => {
    const [data] = response
    if (data) {
      dispatch({ type: authActionTypes.INIT_AUTH, payload: data.auth })
    }
    return response
  })
}
