function fetchClient(endpoint, { body, ...customConfig } = {}) {
  const headers = { 'Content-Type': 'application/json' }
  const config = {
    method: body ? 'POST' : 'GET',
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  }

  if (body) {
    config.body = JSON.stringify(body)
  }

  return window.fetch(`/api${endpoint}`, config).then(async (response) => {
    let data = {}
    if (endpoint.includes('invoices/download')) {
      data = await response.blob().catch((err) => {
        throw response
      })
    } else if (response.status !== 204) {
      data = await response.json().catch((err) => {
        throw err
      })
    }
    if (response.ok) {
      return [data, null]
    }
    return [null, data]
  })
}

export default fetchClient
