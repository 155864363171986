import React from 'react'
import Button from '../../components/Button/Button'
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage'
import { signIn } from '../../context/AuthContext/AuthActions'
import { useAuthUpdate } from '../../context/AuthContext/AuthContext'
import useAsync from '../../hooks/useAsync'

function SignIn({ ...props }) {
  const dispatch = useAuthUpdate()
  const { run, status, errors, resetError } = useAsync()

  function handleSignIn(evt) {
    evt.preventDefault()
    resetError('credentials')

    const { email, password } = evt.target.elements

    run(signIn(dispatch, email.value, password.value))
  }

  return (
    <div style={{ maxWidth: '600px' }}>
      <h2>Sign in</h2>
      <form onSubmit={handleSignIn} style={{ marginTop: '30px' }}>
        <label className="text-label">
          <span>Email</span>
          <input
            className="text-input"
            placeholder="random@email.com"
            autoComplete="email"
            required={true}
            type="email"
            name="email"
          />
        </label>
        <label className="text-label">
          <span>Password</span>
          <input
            className="text-input"
            placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
            autoComplete="password"
            required={true}
            type="password"
            name="password"
          />
        </label>
        <ErrorMessage>{errors?.credentials}</ErrorMessage>
        <Button
          style={{ marginTop: '30px' }}
          loading={status === 'pending'}
          className="light-button"
        >
          Sign in
        </Button>
      </form>
    </div>
  )
}

export default SignIn
