import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  deleteTask,
  updateModal,
  updateTask,
} from '../../../../context/PlannerContext/PlannerActions'
import { usePlannerUpdate } from '../../../../context/PlannerContext/PlannerContext'
import useAsync from '../../../../hooks/useAsync'
import Button from '../../../../components/Button/Button'

function TaskCard({ task, ...props }) {
  return (
    <div className={`task-card-container ${task.status}`}>
      <h3>{task.title}</h3>
      <p className="description">{task.description}</p>
      <ActionContainer task={task} />
      {task.tags?.length > 0 && <Tags tags={task.tags} />}
    </div>
  )
}

function ActionContainer({ task }) {
  const dispatch = usePlannerUpdate()
  const { run } = useAsync()
  const [status, setStatus] = React.useState('idle')

  function handleCompleteTask() {
    setStatus('completing')
    run(updateTask(dispatch, task.id, { status: 'complete' })).then(() =>
      setStatus('idle')
    )
  }

  function handleDeleteTask() {
    setStatus('deleting')
    run(deleteTask(dispatch, task.id))
  }

  function handlePushBackTask() {
    setStatus('pushing-back')

    const taskDate = new Date(task.date)
    const pushedBackDate = new Date(
      taskDate.getFullYear(),
      taskDate.getMonth(),
      taskDate.getDate() + 1,
      taskDate.getHours(),
      taskDate.getMinutes(),
      taskDate.getSeconds()
    )

    run(updateTask(dispatch, task.id, { date: pushedBackDate.getTime() })).then(
      () => setStatus('idle')
    )
  }

  if (task.status === 'complete') return null

  return (
    <div className="action-container">
      <Button
        loading={status === 'completing'}
        className={`${task.status === 'urgent' ? 'dark' : 'light'}-button`}
        onClick={handleCompleteTask}
      >
        <span style={{ marginRight: '7px' }}>Mark as complete</span>
        <FontAwesomeIcon icon="check" />
      </Button>
      <Button
        style={{ marginRight: '10px' }}
        loading={status === 'pushing-back'}
        className={`${task.status === 'urgent' ? 'dark' : 'light'}-button`}
        onClick={handlePushBackTask}
      >
        <span style={{ marginRight: '7px' }}>Push back a day</span>
        <FontAwesomeIcon icon="clock" />
      </Button>
      <Button
        style={{ marginLeft: 'auto' }}
        className={`${task.status === 'urgent' ? 'dark' : 'light'}-button`}
        onClick={() => updateModal(dispatch, `UpdateTask-${task.id}`)}
      >
        <FontAwesomeIcon icon="pencil-alt" />
      </Button>
      <Button
        loading={status === 'deleting'}
        className={`${task.status === 'urgent' ? 'dark' : 'light'}-button`}
        onClick={handleDeleteTask}
      >
        <FontAwesomeIcon icon="trash" />
      </Button>
    </div>
  )
}

function Tags({ tags, ...props }) {
  return (
    <div style={{ display: 'flex', marginTop: '5px', flexWrap: 'wrap' }}>
      {tags.map((tag) => (
        <Tag key={tag.name} tag={tag} />
      ))}
    </div>
  )
}

function Tag({ tag }) {
  return (
    <div
      style={{
        userSelect: 'none',
        height: '25px',
        display: 'flex',
        padding: '0 10px',
        alignItems: 'center',
        justifyContent: 'center',
        color: tag.textColor,
        backgroundColor: tag.backgroundColor,
        borderRadius: '3px',
        marginRight: '10px',
        marginTop: '10px',
        fontWeight: '600',
        fontSize: '0.85rem',
        boxShadow:
          '0 0 5px rgba(0, 0, 0, .2), 2px 2px 3px 1px rgba(0, 0, 0, .1)',
      }}
    >
      {tag.name}
    </div>
  )
}

export default TaskCard
