function Header({ ...props }) {
  return (
    <div
      style={{
        height: '70px',
        display: 'flex',
        alignItems: 'center',
        padding: '0 20px',
        backgroundColor: '#ffffff',
        boxSizing: 'border-box',
        pointerEvents: 'none',
        userSelect: 'none',
      }}
    >
      <span id="header-title">Daily planner</span>
    </div>
  )
}

export default Header
