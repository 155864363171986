import React from 'react'

function useSafeDispatch(dispatch) {
  const mounted = React.useRef(false)

  React.useLayoutEffect(() => {
    mounted.current = true

    return () => {
      mounted.current = false
    }
  }, [])

  return React.useCallback(
    (...args) => {
      return mounted.current ? dispatch(...args) : void 0
    },
    [dispatch]
  )
}

export default useSafeDispatch
