import React from 'react'

import Button from '../../components/Button/Button'
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage'
import TagsInput from '../../components/TagsInput/TagsInput'
import { createTask } from '../../context/PlannerContext/PlannerActions'
import { usePlanner } from '../../context/PlannerContext/PlannerContext'
import useAsync from '../../hooks/useAsync'
import ModalWrapper from '../ModalWrapper'

function NewTask({ exit, ...props }) {
  const { dispatch, date } = usePlanner()
  const { run, status, errors } = useAsync()
  const [tags, setTags] = React.useState([])

  function handleCreateTask(evt) {
    evt.preventDefault()

    const { title, description, urgent } = evt.target.elements

    const taskBody = {
      title: title.value,
      description: description.value,
      tags,
      date: date.getTime(),
    }

    if (urgent.checked) {
      taskBody.status = 'urgent'
    }

    run(createTask(dispatch, taskBody)).then((response) => {
      const [data] = response
      if (data) exit()
    })
  }

  return (
    <ModalWrapper exit={exit} style={{ maxWidth: '640px', padding: '0' }}>
      <form onSubmit={handleCreateTask}>
        <div className="modal-header" style={{ padding: '30px 20px' }}>
          <h2>New Task</h2>
          <button type="button" onClick={exit}>
            X
          </button>
        </div>
        <div className="modal-content">
          <label className="text-label" style={{ padding: '0 20px' }}>
            <span>Title</span>
            <input className="text-input" name="title" type="text" />
            <ErrorMessage style={{ marginTop: '5px' }}>
              {errors?.title}
            </ErrorMessage>
          </label>
          <TagsInput setTags={setTags} tags={tags} />
          <label className="text-label" style={{ padding: '0 20px' }}>
            <span>Description</span>
            <textarea className="textarea-input" name="description" />
            <ErrorMessage style={{ marginTop: '5px' }}>
              {errors?.description}
            </ErrorMessage>
          </label>
          <label className="checkbox-label" style={{ padding: '0 20px' }}>
            <input type="checkbox" name="urgent" />
            <span>Mark task as urgent</span>
          </label>
        </div>
        <div
          className="modal-footer"
          style={{
            justifyContent: 'flex-end',
            paddingLeft: '20px',
            paddingRight: '20px',
          }}
        >
          <Button
            loading={status === 'pending'}
            className="light-button"
            type="submit"
          >
            Add new task
          </Button>
        </div>
      </form>
    </ModalWrapper>
  )
}

export default NewTask
