function ErrorMessage({ children, style = {}, ...props }) {
  if (!children) return null
  return (
    <p style={{ color: 'var(--negative-color)', fontSize: '0.8rem', ...style }}>
      {children}
    </p>
  )
}

export default ErrorMessage
