import React from 'react'

import useSafeDispatch from './useSafeDispatch'

function asyncReducer(state, action) {
  const { type, payload } = action

  switch (type) {
    case 'pending': {
      return { status: 'pending', data: null, errors: null }
    }
    case 'resolved': {
      return { status: 'resolved', data: payload, errors: null }
    }
    case 'rejected': {
      return { status: 'rejected', data: null, errors: payload }
    }
    case 'reset-error': {
      let errors = { ...state.errors }
      if (errors[payload]) delete errors[payload]
      return {
        ...state,
        errors,
      }
    }
    case 'reset-all-errors': {
      return {
        ...state,
        errors: null,
      }
    }
    default: {
      throw new Error(
        `[asyncReducer] Action type not supported: ${type || 'undefined'}.`
      )
    }
  }
}

function useAsync(initialState = {}) {
  const [{ data, errors, status }, unsafeDispatch] = React.useReducer(
    asyncReducer,
    {
      data: null,
      errors: null,
      status: 'init',
      ...initialState,
    }
  )

  const dispatch = useSafeDispatch(unsafeDispatch)

  const run = React.useCallback(
    (promise) => {
      dispatch({ type: 'pending' })
      return promise
        .then((response) => {
          const [data, err] = response
          if (data !== null && data !== undefined) {
            dispatch({ type: 'resolved', payload: data })
          }
          if (err) {
            dispatch({ type: 'rejected', payload: err })
          }
          return response
        })
        .catch((err) => {
          dispatch({ type: 'rejected', payload: err })
        })
    },
    [dispatch]
  )

  const resetError = React.useCallback(
    (type) => {
      if (type === 'all') {
        dispatch({ type: 'reset-all-errors' })
      } else {
        dispatch({ type: 'reset-error', payload: type })
      }
    },
    [dispatch]
  )

  return { run, data, errors, resetError, status }
}

export default useAsync
