import React from 'react'
import Button from '../../components/Button/Button'
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage'
import TagsInput from '../../components/TagsInput/TagsInput'
import { updateTask } from '../../context/PlannerContext/PlannerActions'
import { usePlanner } from '../../context/PlannerContext/PlannerContext'
import useAsync from '../../hooks/useAsync'
import ModalWrapper from '../ModalWrapper'

function UpdateTask({ exit, ...props }) {
  const { tasks, modal, dispatch } = usePlanner()
  const { run, status, errors } = useAsync()
  const task = React.useMemo(() => {
    const task_id = modal.replace('UpdateTask-', '')
    return tasks.find((task) => task.id === task_id)
  }, [modal, tasks])

  const [tags, setTags] = React.useState(() => task.tags || [])

  function handleUpdateTask(evt) {
    evt.preventDefault()

    const { title, description, urgent } = evt.target.elements

    const updatedBody = {}
    if (title.value !== task.title) {
      updatedBody.title = title.value
    }
    if (description.value !== task.description) {
      updatedBody.description = description.value
    }
    if (urgent.checked !== (task.status === 'urgent')) {
      updatedBody.status = urgent.checked ? 'urgent' : 'normal'
    }
    let tagsChanged = false
    for (let i = 0; i < tags.length; i++) {
      const oldTags = task.tags
      const newTag = tags[i]
      const oldTag = oldTags[i]
      if (!oldTag) {
        tagsChanged = true
        break
      }

      if (
        newTag.name === oldTag.name &&
        newTag.textColor === oldTag.textColor &&
        newTag.backgroundColor === oldTag.backgroundColor
      )
        continue
      tagsChanged = true
      break
    }
    if (tagsChanged) {
      updatedBody.tags = tags
    }

    run(updateTask(dispatch, task.id, updatedBody)).then((response) => {
      const [data] = response
      if (data) exit()
    })
  }

  return (
    <ModalWrapper exit={exit} style={{ maxWidth: '640px', padding: '0' }}>
      <form name="update-task" onSubmit={handleUpdateTask}>
        <div
          className="modal-header"
          style={{ paddingLeft: '20px', paddingRight: '20px' }}
        >
          <h2>Update Task</h2>
          <button type="button" onClick={exit}>
            X
          </button>
        </div>
        <div className="modal-content">
          <label className="text-label" style={{ padding: '0 20px' }}>
            <span>Title</span>
            <input
              defaultValue={task.title}
              className="text-input"
              name="title"
              type="text"
            />
          </label>
          <ErrorMessage style={{ marginTop: '5px' }}>
            {errors?.title}
          </ErrorMessage>
          <TagsInput tags={tags} setTags={setTags} />
          <label className="text-label" style={{ padding: '0 20px' }}>
            <span>Description</span>
            <textarea
              defaultValue={task.description}
              className="textarea-input"
              name="description"
            />
          </label>
          <ErrorMessage style={{ marginTop: '5px' }}>
            {errors?.description}
          </ErrorMessage>
          <label className="checkbox-label" style={{ padding: '0 20px' }}>
            <input
              defaultChecked={task.status === 'urgent'}
              type="checkbox"
              name="urgent"
            />
            <span>Mark task as urgent</span>
          </label>
        </div>
        <div
          className="modal-footer"
          style={{ paddingLeft: '20px', paddingRight: '20px' }}
        >
          <Button
            loading={status === 'pending'}
            type="submit"
            className="light-button"
          >
            Update task
          </Button>
        </div>
      </form>
    </ModalWrapper>
  )
}

export default UpdateTask
