import * as tasksClient from '../../utils/tasks-client'
import { plannerActionTypes } from './PlannerContext'

export function fetchTasks(dispatch, date) {
  const startDate = new Date(new Date(date).setHours(0, 0, 0, 0)).getTime()
  const endDate = new Date(new Date(date).setHours(23, 59, 59, 999)).getTime()

  return tasksClient
    .custom(`/?start=${startDate}&end=${endDate}`, null, { method: 'GET' })
    .then((response) => {
      const [data] = response
      if (data) {
        dispatch({ type: plannerActionTypes.INIT_TASKS, payload: data })
      }
      return response
    })
}

export function createTask(dispatch, taskBody) {
  return tasksClient.custom('/', { ...taskBody }).then((response) => {
    const [data] = response
    if (data) {
      dispatch({ type: plannerActionTypes.CREATE_TASK, payload: data })
    }
    return response
  })
}

export function updateTask(dispatch, task_id, updatedBody) {
  return tasksClient
    .custom(`/${task_id}`, { ...updatedBody }, { method: 'PUT' })
    .then((response) => {
      const [data] = response
      if (data) {
        dispatch({
          type: plannerActionTypes.UPDATE_TASK,
          payload: { id: task_id, body: updatedBody },
        })
      }
      return response
    })
}

export function deleteTask(dispatch, task_id) {
  return tasksClient
    .custom(`/${task_id}`, null, { method: 'DELETE' })
    .then((response) => {
      const [data] = response
      if (data) {
        dispatch({ type: plannerActionTypes.DELETE_TASK, payload: task_id })
      }
      return response
    })
}

export function exitModal(dispatch) {
  dispatch({ type: plannerActionTypes.UPDATE_MODAL, payload: null })
}

export function updateModal(dispatch, modal) {
  dispatch({ type: plannerActionTypes.UPDATE_MODAL, payload: modal })
}

export function nextDay(dispatch, current_date) {
  const newDate = new Date(
    current_date.getFullYear(),
    current_date.getMonth(),
    current_date.getDate() + 1,
    current_date.getHours(),
    current_date.getMinutes(),
    current_date.getSeconds()
  )

  dispatch({ type: plannerActionTypes.UPDATE_DATE, payload: newDate })
}

export function prevDay(dispatch, current_date) {
  const newDate = new Date(
    current_date.getFullYear(),
    current_date.getMonth(),
    current_date.getDate() - 1,
    current_date.getHours(),
    current_date.getMinutes(),
    current_date.getSeconds()
  )

  dispatch({ type: plannerActionTypes.UPDATE_DATE, payload: newDate })
}

export function hideTag(dispatch, tagName) {
  dispatch({ type: plannerActionTypes.HIDE_TAG, payload: tagName })
}

export function showTag(dispatch, tagName) {
  dispatch({ type: plannerActionTypes.SHOW_TAG, payload: tagName })
}
