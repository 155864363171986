import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button from '../../../../components/Button/Button'
import {
  hideTag,
  nextDay,
  prevDay,
  showTag,
  updateModal,
} from '../../../../context/PlannerContext/PlannerActions'
import { usePlanner } from '../../../../context/PlannerContext/PlannerContext'
import useBlurWithCallback from '../../../../hooks/useBlurWithCallback'

function Toolbar({ ...props }) {
  const [showFilter, setShowFilter] = React.useState(false)
  const { dispatch, date, filter } = usePlanner()

  function handleNextDay() {
    nextDay(dispatch, date)
  }
  function handlePrevDay() {
    prevDay(dispatch, date)
  }
  console.log(filter.tags.length)

  return (
    <div className="toolbar-container">
      <Button
        className="light-button new-task"
        style={{ marginRight: '10px' }}
        onClick={() => updateModal(dispatch, 'NewTask')}
      >
        <FontAwesomeIcon icon="plus" />
        <span className="hide-mobile" style={{ marginLeft: '10px' }}>
          Add new task
        </span>
      </Button>
      <Button
        className="light-button mobile-right"
        style={{ width: 'max-content', fontSize: '.7rem' }}
        onClick={handlePrevDay}
      >
        <FontAwesomeIcon icon="step-backward" />
      </Button>
      <span
        style={{
          fontWeight: '600',
          color: 'var(--title-color)',
          fontSize: '1rem',
          width: '110px',
          textAlign: 'center',
        }}
      >
        {date.toLocaleDateString()}
      </span>
      <Button
        className="light-button"
        style={{ width: 'max-content', fontSize: '.7rem' }}
        onClick={handleNextDay}
      >
        <FontAwesomeIcon icon="step-forward" />
      </Button>
      <div style={{ position: 'relative', marginLeft: '10px' }}>
        <Button
          className={`${filter.tags.length > 0 ? 'dark' : 'light'}-button`}
          style={{ width: 'max-content' }}
          onClick={() => setShowFilter(true)}
        >
          Filter
        </Button>
        {showFilter && <TagFilter exit={() => setShowFilter(false)} />}
      </div>
    </div>
  )
}

function TagFilter({ exit }) {
  const { tags, filter, dispatch } = usePlanner()
  const containerRef = React.useRef(null)
  useBlurWithCallback(containerRef, () => exit())

  function handleToggleTag(tagName) {
    if (filter.tags.includes(tagName)) {
      showTag(dispatch, tagName)
    } else {
      hideTag(dispatch, tagName)
    }
  }

  return (
    <div
      ref={containerRef}
      className="drop-panel"
      style={{
        position: 'absolute',
        top: '10px',
        backgroundColor: '#ffffff',
        width: '200px',
        borderRadius: '3px',
        boxShadow:
          '0 0 5px rgba(0, 0, 0, 0.1), 2px 2px 3px 2px rgba(0, 0, 0, 0.2)',
        zIndex: '100',
      }}
    >
      <div
        style={{
          height: '50px',
          display: 'flex',
          alignItems: 'center',
          padding: '0 20px',
          boxSizing: 'border-box',
          borderBottom: '1px solid var(--border-color)',
        }}
      >
        <h3 style={{ color: 'var(--title-color)' }}>Filter tags</h3>
      </div>
      <div style={{ maxHeight: '130px', overflowY: 'auto', padding: '20px' }}>
        {tags.map((tag) => (
          <label
            key={tag.name}
            className="checkbox-label"
            style={{ margin: '5px 0' }}
          >
            <input
              type="checkbox"
              checked={!filter.tags.includes(tag.name)}
              onChange={() => handleToggleTag(tag.name)}
            />
            <span>{tag.name}</span>
          </label>
        ))}
      </div>
    </div>
  )
}

export default Toolbar
