import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import { usePlanner } from '../../../../context/PlannerContext/PlannerContext'
import TaskCard from '../TaskCard/TaskCard'

function TaskList({ status, ...props }) {
  const { tasks, date, filter } = usePlanner()

  const filteredTasks = React.useMemo(() => {
    const startDate = new Date(new Date(date).setHours(0, 0, 0, 0)).getTime()
    const endDate = new Date(new Date(date).setHours(23, 59, 59, 999)).getTime()

    tasks.sort((a, b) => {
      if (a.status === b.status) {
        return 0
      }
      if (a.status === 'urgent' || b.status === 'complete') {
        return -1
      }
      if (b.status === 'urgent' || a.status === 'complete') {
        return 1
      }
      return 0
    })

    return tasks.filter(
      (task) =>
        task.date >= startDate &&
        task.date <= endDate &&
        task.tags.findIndex((tag) => !filter.tags.includes(tag.name)) >= 0
    )
  }, [tasks, date, filter.tags])

  const tasksCompleted = React.useMemo(() => {
    return (
      filteredTasks.filter((task) => task.status !== 'complete').length === 0 &&
      filteredTasks.length > 0
    )
  }, [filteredTasks])

  return (
    <div className="task-list-container">
      {status === 'pending' && (
        <p
          className="task-card-message fade-in"
          style={{
            color: '#666',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px solid var(--border-color)',
          }}
        >
          <span
            className="loading-icon"
            style={{
              height: 'min-content',
              width: 'min-content',
              fontSize: '1rem',
            }}
          >
            <FontAwesomeIcon icon="circle-notch" />
          </span>
        </p>
      )}
      {tasksCompleted && status !== 'pending' && (
        <p
          className="task-card-message fade-in"
          style={{
            color: 'var(--positive-color)',
            border: '1px solid var(--positive-color)',
          }}
        >
          You've finished all your tasks here!
        </p>
      )}
      {filteredTasks.map((task) => (
        <TaskCard key={task.id} task={task} />
      ))}
      {status !== 'pending' && filteredTasks.length <= 0 && (
        <p
          className="task-card-message fade-in"
          style={{
            color: '#666',
            border: '1px solid var(--border-color)',
          }}
        >
          There aren't any tasks here...
        </p>
      )}
    </div>
  )
}

export default TaskList
