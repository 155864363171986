import React from 'react'
import useSafeDispatch from '../../hooks/useSafeDispatch'

const PlannerStateContext = React.createContext()
const PlannerUpdateContext = React.createContext()

function PlannerProvider({ children }) {
  const [state, unsafeDispatch] = React.useReducer(reducer, {
    tasks: [],
    filter: {
      tags: [],
    },
    modal: null,
    date: new Date(),
  })

  const tags = React.useMemo(() => {
    let value = []
    for (let task of state.tasks) {
      for (let tag of task.tags) {
        let tagNames = value.map((tg) => tg.name)
        if (!tagNames.includes(tag.name)) {
          value.push(tag)
        }
      }
    }
    return value
  }, [state.tasks])

  const stateValue = React.useMemo(() => ({ ...state, tags }), [state, tags])
  const dispatch = useSafeDispatch(unsafeDispatch)

  React.useEffect(() => {
    for (let tag of state.filter.tags) {
      if (tags.findIndex((tg) => tg.name === tag) < 0) {
        dispatch({ type: plannerActionTypes.SHOW_TAG, payload: tag })
      }
    }
  }, [tags, dispatch, state.filter.tags])

  return (
    <PlannerStateContext.Provider value={stateValue}>
      <PlannerUpdateContext.Provider value={dispatch}>
        {children}
      </PlannerUpdateContext.Provider>
    </PlannerStateContext.Provider>
  )
}

const plannerActionTypes = {
  INIT_TASKS: 'init tasks',
  CREATE_TASK: 'create task',
  UPDATE_TASK: 'update task',
  DELETE_TASK: 'delete task',
  COMPLETE_TASK: 'complete task',
  UPDATE_DATE: 'update date',
  HIDE_TAG: 'hide tag',
  SHOW_TAG: 'show tag',
}

function reducer(state, action) {
  const { type, payload } = action

  switch (type) {
    case plannerActionTypes.INIT_TASKS: {
      let ids = payload.map((task) => task.id)

      return {
        ...state,
        tasks: [
          ...state.tasks.filter((task) => !ids.includes(task.id)),
          ...payload,
        ],
      }
    }
    case plannerActionTypes.CREATE_TASK: {
      return {
        ...state,
        tasks: [...state.tasks, payload],
      }
    }
    case plannerActionTypes.UPDATE_TASK: {
      return {
        ...state,
        tasks: state.tasks.map((task) => {
          if (task.id === payload.id) {
            return {
              ...task,
              ...payload.body,
            }
          }
          return task
        }),
      }
    }
    case plannerActionTypes.DELETE_TASK: {
      return {
        ...state,
        tasks: state.tasks.filter((task) => task.id !== payload),
      }
    }
    case plannerActionTypes.UPDATE_DATE: {
      return {
        ...state,
        date: payload,
      }
    }
    case plannerActionTypes.UPDATE_MODAL: {
      return {
        ...state,
        modal: payload,
      }
    }
    case plannerActionTypes.HIDE_TAG: {
      return {
        ...state,
        filter: {
          ...state.filter,
          tags: [...state.filter.tags, payload],
        },
      }
    }
    case plannerActionTypes.SHOW_TAG: {
      return {
        ...state,
        filter: {
          ...state.filter,
          tags: state.filter.tags.filter((tag) => tag !== payload),
        },
      }
    }
    default: {
      throw new Error(
        `[plannerReducer] Action type not supported: ${type || 'undefined'}.`
      )
    }
  }
}

function usePlannerState() {
  const context = React.useContext(PlannerStateContext)
  if (context === undefined) {
    throw new Error(
      `[usePlanner] must only be used within a <PlannerProvider> context provider`
    )
  }
  return context
}

function usePlannerUpdate() {
  const context = React.useContext(PlannerUpdateContext)
  if (context === undefined) {
    throw new Error(
      `[usePlanner] must only be used within a <PlannerProvider> context provider`
    )
  }
  return context
}

function usePlanner() {
  return { ...usePlannerState(), dispatch: usePlannerUpdate() }
}

export { PlannerProvider, usePlanner, usePlannerUpdate, plannerActionTypes }
