import Header from './components/Header/Header'
import DailyPlanner from './views/DailyPlanner/DailyPlanner'

import './lib/inputs.css'
import { useAuth } from './context/AuthContext/AuthContext'
import SignIn from './views/SignIn/SignIn'

function App() {
  const { auth } = useAuth()

  if (!auth)
    return (
      <div style={{ padding: '30px', boxSizing: 'border-box' }}>
        <SignIn />
      </div>
    )

  return (
    <>
      <Header />
      <div style={{ height: 'calc(100% - 70px)' }}>
        <DailyPlanner />
      </div>
    </>
  )
}

export default App
