import React from 'react'

function useBlurWithCallback(ref, callback = () => {}, buttonClass) {
  const useBlur = React.useRef()
  useBlur.current = callback

  React.useEffect(() => {
    function handleMouseDown(evt) {
      if (
        ref.current &&
        !ref.current.contains(evt.target) &&
        (buttonClass ? !evt.target.className.includes(buttonClass) : true)
      ) {
        useBlur.current()
      }
    }

    function handleKeyDown(evt) {
      if (evt.key === 'Escape') {
        useBlur.current()
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    document.addEventListener('mousedown', handleMouseDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('mousedown', handleMouseDown)
    }
  }, [ref, buttonClass])
}

export default useBlurWithCallback
