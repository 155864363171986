import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { usePlanner } from '../../context/PlannerContext/PlannerContext'

function TagsInput({ setTags, tags, ...props }) {
  const { tags: existingTags } = usePlanner()
  const [name, setName] = React.useState('')
  const [backgroundColor, setBackgroundColor] = React.useState('#ffffff')
  const [textColor, setTextColor] = React.useState('#666666')

  React.useEffect(() => {
    const tagNames = existingTags.map((tag) => tag.name)
    if (tagNames.includes(name)) {
      const tag = existingTags.find((tag) => tag.name === name)
      setBackgroundColor(tag.backgroundColor)
      setTextColor(tag.textColor)
    }
  }, [existingTags, name])

  function addTag() {
    const nameString = name?.trim() || ''
    const tagNames = tags.map((tag) => tag.name)
    if (nameString && !tagNames.includes(nameString)) {
      setTags((c) => [...c, { name: nameString, backgroundColor, textColor }])
      setName('')
      setBackgroundColor('#ffffff')
      setTextColor('#666666')
    }
  }

  function removeTag(name) {
    setTags((c) => c.filter((tag) => tag.name !== name))
  }

  return (
    <div
      style={{ backgroundColor: '#894bc0', padding: '20px', marginTop: '20px' }}
    >
      <div
        style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'flex-end' }}
      >
        <label className="text-label">
          <span style={{ color: '#ffffff', marginBottom: '10px' }}>Tags</span>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <input
              className="text-input"
              style={{
                width: '120px',
                border: '1px solid transparent',
                boxSizing: 'border-box',
              }}
              onKeyDown={(evt) => {
                if (evt.key === 'Enter') {
                  evt.preventDefault()
                  addTag()
                }
              }}
              name="new-tag"
              value={name}
              onChange={(evt) => setName(evt.target.value)}
              type="text"
            />
            <input
              type="color"
              style={{
                width: '38px',
                height: '38px',
                marginLeft: '10px',
                background: '#fff',
                border: '0',
              }}
              value={backgroundColor}
              onChange={(evt) => setBackgroundColor(evt.target.value)}
            />
            <input
              type="color"
              style={{
                width: '38px',
                height: '38px',
                marginLeft: '10px',
                background: '#fff',
                border: '0',
              }}
              value={textColor}
              onChange={(evt) => setTextColor(evt.target.value)}
            />
            <button
              type="button"
              style={{
                width: '38px',
                marginLeft: '10px',
                marginRight: '10px',
                fontSize: '0.7rem',
                borderRadius: '3px',
              }}
              className="dark-button"
              onClick={(evt) => {
                addTag()
                evt.target.blur()
              }}
            >
              <FontAwesomeIcon icon="plus" />
            </button>
          </div>
        </label>
        {tags.map((tag) => (
          <Tag key={tag.name} tag={tag} removeTag={() => removeTag(tag.name)} />
        ))}
      </div>
    </div>
  )
}

function Tag({ tag, removeTag }) {
  return (
    <div
      style={{
        borderRadius: '3px',
        height: '38px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxSizing: 'border-box',
        marginTop: '10px',
        marginRight: '10px',
      }}
    >
      <div
        style={{
          height: '38px',
          padding: '0 12px',
          fontWeight: '600',
          borderRadius: '3px',
          display: 'flex',
          alignItems: 'center',
          backgroundColor: tag.backgroundColor,
          boxShadow:
            '0 0 5px rgba(0, 0, 0, .2), 2px 2px 5px 3px rgba(0, 0, 0, .2)',
        }}
      >
        <span
          style={{
            fontSize: '0.85rem',
            color: tag.textColor,
          }}
        >
          {tag.name}
        </span>
        <button
          type="button"
          onClick={removeTag}
          style={{
            color: tag.textColor,
            marginLeft: '12px',
            fontSize: '0.7rem',
            cursor: 'pointer',
            border: 0,
            padding: 0,
            background: 'none',
          }}
        >
          <FontAwesomeIcon icon="times" />
        </button>
      </div>
    </div>
  )
}

export default TagsInput
