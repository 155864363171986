import React from 'react'
import ReactDOM from 'react-dom'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faTrash,
  faPencilAlt,
  faCheck,
  faClock,
  faArrowRight,
  faArrowLeft,
  faStepForward,
  faStepBackward,
  faCircleNotch,
  faTimes,
  faPlus,
} from '@fortawesome/free-solid-svg-icons'

import './reset.css'
import './index.css'
import App from './app/App'
import { PlannerProvider } from './app/context/PlannerContext/PlannerContext'
import { AuthProvider } from './app/context/AuthContext/AuthContext'

library.add(
  faTrash,
  faPencilAlt,
  faCheck,
  faClock,
  faArrowRight,
  faArrowLeft,
  faStepForward,
  faStepBackward,
  faCircleNotch,
  faTimes,
  faPlus
)

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <PlannerProvider>
        <App />
      </PlannerProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
