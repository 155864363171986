import React from 'react'

import NewTask from '../../modals/NewTask/NewTask'
import { usePlanner } from '../../context/PlannerContext/PlannerContext'
import {
  exitModal,
  fetchTasks,
} from '../../context/PlannerContext/PlannerActions'
import useAsync from '../../hooks/useAsync'
import TaskList from './components/TaskList/TaskList'
import UpdateTask from '../../modals/UpdateTask/UpdateTask'
import Toolbar from './components/Toolbar/Toolbar'

import '../../lib/daily-planner.css'

function DailyPlanner({ ...props }) {
  const { date, modal, dispatch } = usePlanner()
  const { run, status } = useAsync()

  React.useEffect(() => {
    run(fetchTasks(dispatch, date.getTime()))
  }, [run, date, dispatch])

  function exit() {
    exitModal(dispatch)
  }

  return (
    <div style={{ height: '100%' }}>
      <Toolbar />
      <TaskList status={status} />
      {modal === 'NewTask' && <NewTask exit={exit} />}
      {modal && modal.includes('UpdateTask') && <UpdateTask exit={exit} />}
    </div>
  )
}

export default DailyPlanner
