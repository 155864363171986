import React from 'react'

import useAsync from '../../hooks/useAsync'
import useSafeDispatch from '../../hooks/useSafeDispatch'
import { fetchAuth } from './AuthActions'

const AuthStateContext = React.createContext()
const AuthUpdateContext = React.createContext()

function AuthProvider({ children }) {
  const [state, unsafeDispatch] = React.useReducer(reducer, {
    auth: false,
  })
  const { run } = useAsync()
  const dispatch = useSafeDispatch(unsafeDispatch)

  React.useEffect(() => {
    run(fetchAuth(dispatch))
  }, [run, dispatch])

  return (
    <AuthStateContext.Provider value={state}>
      <AuthUpdateContext.Provider value={dispatch}>
        {children}
      </AuthUpdateContext.Provider>
    </AuthStateContext.Provider>
  )
}

const authActionTypes = {
  INIT_AUTH: 'initialise auth',
}

function reducer(state, action) {
  const { type, payload } = action

  switch (type) {
    case authActionTypes.INIT_AUTH: {
      return {
        ...state,
        auth: payload,
      }
    }
    default: {
      throw new Error(
        `[authReducer] Action type not supported: ${type || 'undefined'}.`
      )
    }
  }
}

function useAuthState() {
  const context = React.useContext(AuthStateContext)
  if (context === undefined) {
    throw new Error(
      `[useAuth] must only be used within a <AuthProvider> context provider`
    )
  }
  return context
}

function useAuthUpdate() {
  const context = React.useContext(AuthUpdateContext)
  if (context === undefined) {
    throw new Error(
      `[useAuth] must only be used within a <AuthProvider> context provider`
    )
  }
  return context
}

function useAuth() {
  return {
    ...useAuthState(),
    dispatch: useAuthUpdate(),
  }
}

export { AuthProvider, useAuth, useAuthUpdate, authActionTypes }
