import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function Button({ loading, children, ...props }) {
  return (
    <button {...props}>
      {loading ? (
        <i className="loading-icon">
          <FontAwesomeIcon icon="circle-notch" />
        </i>
      ) : (
        children
      )}
    </button>
  )
}

export default Button
