import ReactDOM from 'react-dom'
import React from 'react'

import useBlurWithCallback from '../hooks/useBlurWithCallback'

import '../lib/modals.css'

function ModalWrapper({ exit, children, style = {}, ...props }) {
  const innerModalRef = React.useRef(null)

  useBlurWithCallback(innerModalRef, exit)

  return ReactDOM.createPortal(
    <div
      className="fade-in"
      style={{
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        backgroundColor: '#0b0720aa',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        ref={innerModalRef}
        className="modal-inner-container"
        style={{
          maxHeight: '100%',
          backgroundColor: '#ffffff',
          padding: '0 20px',
          minWidth: '40%',
          ...style,
        }}
      >
        {children}
      </div>
    </div>,
    document.querySelector('#root')
  )
}

export default ModalWrapper
